<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        จัดการผู้ใช้งาน
      </h3>
      <div class="card-toolbar">
        <vue3-json-excel
          :json-data="dataItemsShow"
          :fields="json_fields"
          type="xls"
          name="ข้อมูลผู้ใช้งาน.xls"
          class="me-2"
        >
          <button
            class="btn btn-sm btn-light btn-success"
            style="font-size: 14px; font-weight: bold"
          >
            <span class="svg-icon svg-icon-2x dark">
              <inline-svg src="media/Excel.svg" />
              Download Excel
            </span>
          </button>
        </vue3-json-excel>
        <button
        v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>

    <div class="card-body pt-3">
      <!-- searchbar -->
      <!-- <Search @textSearch="textSearch" /> -->
      <!-- searchbar -->

      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'Username'">
              {{ $t("Username") }}
            </template>
            <template v-if="column.dataIndex === 'FullName'">
              {{ $t("Full Name") }}
            </template>
            <template v-if="column.dataIndex === 'Role'">
              {{ $t("Role") }}
            </template>
            <template v-if="column.dataIndex === 'Warehouse'">
              {{ $t("Warehouse") }}
            </template>
            <template v-if="column.dataIndex === 'action'">
              {{ $t("action") }}
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'Username'">
              <span>{{ record.username }}</span>
            </template>
            <template v-if="column.dataIndex === 'FullName'">
              <span>{{ record.firstName }} {{ record.lastName }}</span>
            </template>
            <template v-if="column.dataIndex === 'Role'">
              <span>{{ record.role }}</span>
            </template>
            <template v-if="column.dataIndex === 'Warehouse'">
              <span>
                {{
                  record.warehouse
                    ? record.warehouse.warehouse_short_name +
                      " | " +
                      record.warehouse.warehouse_full_name
                    : ""
                }}
              </span>
            </template>
            <!-- <template v-if="column.dataIndex === 'status'">
              <span
                v-if="record.status == 'ขายปกติ'"
                style="color: green"
              ></span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template> -->
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div class="col-sm-6 d-flex justify-content-md-end" v-if="userAuth.isCanModified">
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>
        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>#</th>
              <th>Username</th>
              <th>Full Name</th>
              <th>Role</th>
              <th>Warehouse</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.role }}</td>
              <td>
                {{
                  item.warehouse
                    ? item.warehouse.warehouse_short_name +
                      " | " +
                      item.warehouse.warehouse_full_name
                    : ""
                }}
              </td>

              <td>
                <div class="row justify-content-center">
                  <div class="col-sm-2 justify-content-center">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-2 justify-content-md-start">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import userApi from "@/api/user/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";
import { Decode, Encode } from "@/services";

export default {
  setup() {
    document.title = "J&N | เพิ่มประเภทสินค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    json_fields: {
      username: "username",
      firstName: "firstName",
      lastName: "lastName",
      role: "role",

      // cost: {
      //   field: "productPrices",
      //   callback: (value) => {
      //     return ` ${value[0] ? value[0].cost : ""}`;
      //   },
      // },
      // status: "status",
    },
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "Username", dataIndex: "Username" },
      { title: "Full Name", dataIndex: "FullName" },
      { title: "Role", dataIndex: "Role" },
      { title: "Warehouse", dataIndex: "Warehouse" },
      { title: "", dataIndex: "action" },
    ],
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    allData: [],
    user: [],
    userAuth: [],
    userLocal: [],
    masterMenuId: 26,
  }),

  async created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("user_temp")));

    await this.getAll();
    this.checkMenu();
  },

  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      console.log("this.userLocal", this.userLocal);
      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAll() {
      if (this.user.role == "SUPER_ADMIN") {
        this.loading = true;
        let responseData = [];
        try {
          responseData = await userApi.user.getAll(
            localStorage.getItem("companyId")
          );
        } catch (error) {
          console.log(error);
        }
        if (responseData.response_status === "SUCCESS") {
          this.dataItemsShow = responseData.data;
          this.dataItemsShow.forEach((element, index) => {
            element.no = index + 1;
          });
          this.tableItems = responseData.data;
          this.allData = responseData.data;
          this.loading = false;
        } else {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }
      } else if (this.user.role == "ADMIN") {
        this.loading = true;
        let responseData = [];
        try {
          responseData = await userApi.user.getAll(
            localStorage.getItem("companyId")
          );
        } catch (error) {
          console.log(error);
        }
        if (responseData.response_status === "SUCCESS") {
          this.dataItemsShow = responseData.data;
          this.dataItemsShow.forEach((element, index) => {
            element.no = index + 1;
          });
          this.tableItems = responseData.data;
          this.allData = responseData.data;
          this.loading = false;
        } else {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "ไม่มีสิทธิเข้าถึง",
          text: "กรุณาติดต่อ SUPERADMIN",
        }).then(() => {
          this.$router.push("/dashboard");
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push({
        path: "/config/user/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        name: "USER-edit",
        query: {
          id: id,
        },
      });
    },
    goToView(id) {
      this.$router.push({
        name: "USER-view",
        query: {
          id: id,
        },
      });
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        // {{ $t("search") }}
        title: `${this.$t("want2delete")} ${item.username} ${this.$t(
          "ornot"
        )} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await userApi.user.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: `${this.$t("deletesuccessfull")} `,
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await customerAPI.route.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;
      // let responseSearch = [];
      // responseSearch = await userApi.user.searchQuery(
      //   localStorage.getItem("companyId"),
      //   this.searchInput
      // );

      const responseSearch = await userApi.user.search({
        search: this.searchInput,
        companyId: localStorage.getItem("companyId"),
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
